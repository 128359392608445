import React, { useEffect, useState, useRef } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StyledTwoColumnsImage } from "./StyledElements/StyledTwoColumnsImage"


gsap.registerPlugin(ScrollTrigger);


const TwoColumnsImage = props => {

const ACF = props.data

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
      revealRefs.current.forEach((el, index) => {
        gsap.fromTo(el, {

        }, {
          duration: 1.5,

          ease: 'none',
          scrollTrigger: {
            id: `section-${index+1}`,
            trigger: el,
            start: 'top center+=100',
            end: 'bottom center+=20',
            toggleActions: 'play none none reverse',
            toggleClass: {targets: el, className: "is-active"},
            scrub: true,
          }
        });

      });
  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  return (
    <StyledTwoColumnsImage>
        <div className="row">
            <div className="column column--image">
                <div className="wrapper">
                    <GatsbyImage
                        className="image image--right"
                        image={getImage(ACF.campaignImage.localFile)}
                        alt={ACF.campaignImage.altText}
                        title={ACF.campaignImage.title}
                    />
                </div>
            </div>
            <div className="column column--text">
                <div className="wrapper">
                    <h2 className="section-title"
                        dangerouslySetInnerHTML={{
                        __html: ACF.campaignTitle,
                        }}
                    />
                    <div className="text">{ACF.campaignText}</div>
                </div>
            </div>
        </div>
    </StyledTwoColumnsImage>
  )
}

export default TwoColumnsImage
