import React, { useEffect, useState, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StyledVerticalSlider } from "./StyledElements/StyledVerticalSlider"
import ColorCircleElement  from "../../atoms/ColorCirlce"


const VerticalSlider = props => {


  gsap.registerPlugin(ScrollTrigger);



    const revealRefs = useRef([]);
    revealRefs.current = [];

    useEffect(() => {
        ScrollTrigger.matchMedia({
          "(max-width: 799px)": function() {
            revealRefs.current.forEach((el, index) => {

                  gsap.fromTo(el, {
                    y: 90,
                    autoAlpha: 0
                  }, {
                    duration: 2.5,
                    autoAlpha: 1,
                    y: 0,
                    ease: 'none',
                    scrollTrigger: {
                      id: `section-${index+1}`,
                      trigger: el,
                      start: 'top-=140 center+=40',
                      end: 'center center',
                      toggleActions: 'play none none reverse',
                      scrub: true,
                    }
                  });
              });
          }
        })

        revealRefs.current.forEach((el, index) => {
          if (el.classList.contains('element--text')) {
            gsap.fromTo(el, {
              y: 90,
              autoAlpha: 0
            }, {
              duration: 2.5,
              autoAlpha: 1,
              y: 0,
              ease: 'none',
              scrollTrigger: {
                id: `section-${index+1}`,
                trigger: el,
                start: 'top-=140 center+=40',
                end: 'center center',
                toggleActions: 'play none none reverse',
                scrub: true,

              }
            });
          }
        });
    }, []);

    const addToRefs = el => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

    let SliderArray = '';

    if (props.imageSlider) {
       SliderArray = props.imageSlider.map((item, index) => (
        <div key={index} className="element element--image" ref={addToRefs}>
          <GatsbyImage
          className="image image--right"
          image={getImage(item.slideImage.localFile)}
          alt={item.slideImage.altText}
          title={item.slideImage.title}
          objectFit='contain'
        />
        </div>
      ))
    }
    if (props.textSlider) {
       SliderArray = props.textSlider.map((item, index) => (
        <div key={index} className="element element--text" ref={addToRefs}>
          <span className="element__label">{item.label}</span>
          <span className="element__number">{item.number}</span>

          {item.text
            ? <span className="element__text">{item.text}</span>
            : ''
            }
        </div>
      ))
    }



  return (
    <StyledVerticalSlider>
        <div className="row">
            <div id="StickyColumn" className="column column--static">
                <div className="fixed" style={{ background: props.backgroundColor}}>
                  <h3
                  className="title"
                  dangerouslySetInnerHTML={{
                  __html: props.title
                  }}/>
                  <span className="text" dangerouslySetInnerHTML={{
                        __html: props.text,
                        }}/>
                </div>
                {props.backgroundColor
                  ? ''
                  : <ColorCircleElement
                  color="#FED9D8"
                  width="1373px"
                  height="1373px"
                  left='-615px'
                  bottom='-300px'
                  />
                }

            </div>
            <div className="column column--vertical" style={{ background: props.slidesBackground}}>{SliderArray}</div>
        </div>

    </StyledVerticalSlider>
  )
}

export default VerticalSlider
