import styled from "styled-components"

export const StyledTwoColumnsImage = styled.section`
    position: relative;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.black};
    padding: 2rem 0;

    .row {
        display: flex;
        flex-direction: column;

        @media ${props => props.theme.sizes.laptop} {
            flex-direction: row;
        }
    }
    .column {
       @media ${props => props.theme.sizes.laptop} {
            width: 50%;
        }

       &--text {

        @media ${props => props.theme.sizes.laptop} {
            padding: 5% 0;
        }

        .section-title {
            color: black;

            p {
                font-size: 3rem;
                line-height: 1;
                font-weight: 400;

                @media ${props => props.theme.sizes.laptop} {
                    font-size: 5rem;
                }
                @media ${props => props.theme.sizes.desktop} {
                    font-size: 100px;
                }
            }
        }
        .text {
            font-size: 20px;
        }
       }
       &--image {
        background: #BEDCFF;
       }

       .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 9vw;
       }
    }
`