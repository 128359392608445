import styled from "styled-components"

export const StyledPageSummary = styled.section`
    font-family: ${props => props.theme.fonts.primary};
    text-align: left;
    overflow: visible;
    position: relative;
    padding-bottom: 100px;
    color: black;


    @media ${props => props.theme.sizes.laptop} {
        padding-bottom: 200px;
        padding-top: 250px;
    }

    .summary {
        &__content {
            padding: 5vw 5vw 5vw 7.5vw;
        }
        &__text {
            p {
                font-size: 1.3rem;
                font-weight: 500;

                @media ${props => props.theme.sizes.laptop} {
                  font-size: 40px;
                }
            }
        }
    }
    .circleElement {
      @media (max-width: 991.98px) {
        width: 120vw !important;
        height: 120vw !important;
        left: -30vw !important;
      }
    }
`

export const MoreButton = styled.div`

  font-family: ${props => props.theme.fonts.primary};
  font-size: 30px;
  font-weight: 700;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 20;
  padding: 5% 5vw;

@media ${props => props.theme.sizes.laptop} {
  font-size: 47px;
  padding: 0;
}

a {
  color: ${props => props.theme.colors.black};
  text-decoration: none;
}

`
