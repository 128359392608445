import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { StyledExtScreen } from "./StyledElements/StyledExtScreen"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ColorCircleElement  from "../../atoms/ColorCirlce"

const SMSociety = props => {

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
      revealRefs.current.forEach((el, index) => {

        gsap.fromTo(el, {
          x: -300,
          autoAlpha: 0
        }, {
          x: 0,
          duration: 1.5,
          autoAlpha: 1,
          ease: 'none',

          scrollTrigger: {
            id: `section-${index+1}`,
            trigger: el,
            start: 'top center+=200',
            end: 'center-=10 center-=50',
            toggleActions: 'play none none reverse',
            scrub: true,
          }
        });

      });
  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  return (
      <StyledExtScreen CircleElementHeight='10px' >
        <div className="row">
            <h4 className="title" dangerouslySetInnerHTML={{
            __html: props.title
            }}/>
        </div>
        <div className="row">
          <div className="column column--half column--screen">
          {props.circle1 ?
            <div className="cirlce" style={{position: props.circle1.position}}>
              <ColorCircleElement
                color= {props.circle1.color}
                width= {props.circle1.width}
                height={props.circle1.height}
                left={props.circle1.left}
                right={props.circle1.right}
                top={props.circle1.top}
                bottom={props.circle1.bottom}
              />
            </div>
            : ''}
              <GatsbyImage
              className="image image--right"
              image={getImage(props.image1.localFile)}
              alt={props.image1.altText}
              title={props.image1.title}
              />
          </div>
          <div className="column column--half column--screen">
            <div className="text text--society">{props.text}</div>
            {props.circle2 !== undefined
            ?
            <div className="cirlce" style={{position: props.circle2.position}}>
              <ColorCircleElement
                color= {props.circle2.color}
                width= {props.circle2.width}
                height={props.circle2.height}
                left={props.circle2.left}
                right={props.circle2.right}
                top={props.circle2.top}
                bottom={props.circle2.bottom}
                position={props.circle2.position}
              />
            </div>
            : ''}

            <GatsbyImage
              className="image image--right"
              image={getImage(props.image2.localFile)}
              alt={props.image2.altText}
              title={props.image2.title}
              />
          </div>
        </div>
      </StyledExtScreen>
  )
}

export default SMSociety
