import styled from "styled-components"

export const StyledExtScreen = styled.section`
    position: relative;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.black};
    padding: 2rem 0;

    .row {
        display: flex;
        flex-direction: column;
        padding: 0 9vw;

        @media ${props => props.theme.sizes.laptop} {
            flex-direction: row;
        }
    }
    .column {
        @media ${props => props.theme.sizes.laptop} {
            width: 33.3%;
        }

        &--text {
            padding: 2rem 0;

            @media ${props => props.theme.sizes.laptop} {
                padding: 10rem 0 0;
            }
        }
        &--screen {
            position: relative;

            .cirlce {
                /* position: sticky; */
                height: ${props => props.CircleElementHeight};
                width: 100%;
                top: 0;
                display: none;

                @media ${props => props.theme.sizes.laptop} {
                    display: block;
                }
            }
            .image {
                transform: scale(.9);

                @media ${props => props.theme.sizes.laptopL} {
                    transform: scale(1);
                }
            }
        }

        &--half {
            @media ${props => props.theme.sizes.laptop} {
                width: 50%;
            }
        }
    }
    .title {
        color: ${props => props.theme.colors.black};
        margin: 0;

        @media ${props => props.theme.sizes.laptop} {
            margin: 3rem 0;
        }

        p {
            font-size: 3rem;
            line-height: 1.3;
            font-weight: 400;
            margin: 0 0 2rem 0;
            word-break: break-all;

            @media ${props => props.theme.sizes.laptop} {
                font-size: 4.4rem;
                line-height: 5rem;
            }
        }
    }
    .text {
        max-width: 45ch;
        font-size: 1.55rem;

        &--society {
            margin-bottom: 3rem;
        }
    }
    .carouselText {
        max-width: 55ch;
        padding-bottom: 3rem;
        margin-top: 6rem;
    }
`