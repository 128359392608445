import React, { useRef, useEffect } from 'react'

import Layout from "../../components/layout"
import { SEO } from "../../components/seo"

import BigTitle from "../../components/organisms/OfferlandingPages/BigTitle";
import FloatingLine from "../../components/organisms/OfferlandingPages/FloatingLine";
import ConecptIntro from "../../components/organisms/OfferlandingPages/ConceptIntro";
import ImageText from "../../components/organisms/OfferlandingPages/ImageText";
import VerticalSlider from "../../components/organisms/OfferlandingPages/VerticalSlider";
import ExtendedScreenshots from "../../components/organisms/OfferlandingPages/ExtendedScreenshots";
import Reactions from "../../components/organisms/OfferlandingPages/Reactions";
import AnimationVideo from "../../components/organisms/OfferlandingPages/AnimationVideo";
import TwoColumnsImage from "../../components/organisms/OfferlandingPages/TwoColumnsImage";
import SMSociety from "../../components/organisms/OfferlandingPages/SMSociety";
import SMMeme from "../../components/organisms/OfferlandingPages/SMMeme";
import PageSummary from "../../components/organisms/OfferlandingPages/PageSummary";


const Page = props => {
  const SEO = props.pageContext.seo
  const ACF = props.pageContext.ACF_page_sociale

  // const containerRef = useRef(null)
  const scrollRef = React.createRef();

  useEffect(() => {


  });

  return (
    <Layout seoData={SEO} seoUrl={props.pageContext.uri}>
      <div className="scroll">
        <BigTitle
        title={props.pageContext.title}
        />
        <ConecptIntro
        data={ACF.introtextmain}
        />
        <ImageText
        data={ACF}
        />
        <VerticalSlider title={ACF.sliderTitle} text={ACF.sliderText} imageSlider={ACF.sliderImages} />
        <FloatingLine
        firstLine={ACF.esFloatingTitle}
        />
        <ExtendedScreenshots data={ACF}/>
        <AnimationVideo data={ACF}/>
        <Reactions data={ACF}/>
        <TwoColumnsImage data={ACF}/>
        <SMSociety
          title={ACF.customerTitle}
          text={ACF.customerText}
          image1={ACF.customerImage2}
          image2={ACF.customerImage1}
          circle1={{
            color: "#E63C45",
            width: "377px",
            height: "377px",
            left:"-100px",
            top:'20%',
            position: 'relative'
          }}
          circle2={{
            color: "#131DF1",
            width: "377px",
            height: "377px",
            bottom: '0',
            right: '140px'
          }}
          data={ACF}/>
        <VerticalSlider title={ACF.addsTitle} text={ACF.addsText} imageSlider={ACF.addsScreens} backgroundColor={'#B8E8C4'} slidesBackground={'#f6f6f6'} />
        <SMSociety
          title={ACF.societyTitle}
          text={ACF.societyText}
          image1={ACF.societyImage1}
          image2={ACF.societyImage2} data={ACF}
          circle1={{
            color: "#FFF14B",
            width: "1000px",
            height: "1000px",
            left:"-500px",
            top: 'unset',
            bottom:'-1000px',
            position: 'sticky'
          }}
          circle2= {undefined}
        />
        <SMMeme
          title={ACF.memeTitle}
          text={ACF.memeText}
          image={ACF.memeImage}
          circle1={{
            color: "#A5DCD6",
            width: "430px",
            height: "430px",
            right:"0",
            bottom:'0'
          }}
        />
        <VerticalSlider title={ACF.effectsTitle} text={ACF.effectsText} textSlider={ACF.effectsNumers} backgroundColor={'transparent'} />
        <PageSummary
          text={ACF.summaryText}
          link={ACF.summaryLink}
          circle={{
            color: "#FFD09B",
            width: "1000px",
            height: "1000px",
            left:"-400px",
            bottom:'0'
          }}
        />
      </div>
    </Layout>

  )
}
export default Page

export const Head = props => {
  return (
   <SEO title={props.pageContext.seo.title} description={props.pageContext.seo.opengraphDescription} image={props.pageContext.seo?.opengraphImage?.localFile.childImageSharp.fixed.src} pathname={props.pageContext.seo.opengraphUrl} />
  )
}
