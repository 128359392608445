import React, { useEffect, useState, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StyledTextImage } from "./StyledElements/StyledImageText"



const ImageText = props => {


    gsap.registerPlugin(ScrollTrigger);

    const revealRefs = useRef([]);
    revealRefs.current = [];

    useEffect(() => {
        ScrollTrigger.matchMedia({
          "(min-width: 1600px)": function() {
            revealRefs.current.forEach((el, index) => {

              if (el.classList.contains('row__text')) {
                gsap.fromTo(el, {
                  autoAlpha: 0
                }, {
                  duration: 1.5,
                  autoAlpha: 1,
                  ease: 'none',
                  scrollTrigger: {
                    id: `section-${index+1}`,
                    trigger: el,
                    start: 'top-=50 center+=100',
                    end: 'center-=50 center+=50',
                    toggleActions: 'play none none reverse',
                    scrub: true,
                  }
                });
              } else {
                gsap.fromTo(el, {
                  y: -10,
                }, {
                  duration: 1.5,
                  y: 90,
                  ease: 'none',
                  scrollTrigger: {
                    id: `section-${index+1}`,
                    trigger: el,
                    start: 'top center+=60',
                    end: 'center+=80 center+=50',
                    toggleActions: 'play none none reverse',
                    scrub: true,
                  }
                });
              }
            });

          }
        })
        ScrollTrigger.matchMedia({
          "(max-width: 1599px)": function() {
            revealRefs.current.forEach((el, index) => {
                gsap.fromTo(el, {
                  autoAlpha: 0,
                  y: 30,
                }, {
                  duration: 1.5,
                  autoAlpha: 1,
                  y: 0,
                  ease: 'none',
                  scrollTrigger: {
                    id: `section-${index+1}`,
                    trigger: el,
                    start: 'top-=50 center+=100',
                    end: 'center-=50 center+=50',
                    toggleActions: 'play none none reverse',
                    scrub: true,
                  }
                });
            });

          }
        })


    }, []);

    const addToRefs = el => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

  const elements = props.data;

  return (
    <StyledTextImage>
          <div className="row">
            <div ref={addToRefs} className="regularText row__text">
             {props.data.imageintrotext}
            </div>
            <div ref={addToRefs} className="row__image">
              <GatsbyImage
                className="image image--right"
                image={getImage(props.data.introimage1.localFile)}
                alt={props.data.introimage1.altText}
                title={props.data.introimage1.title}
              />
            </div>
          </div>
          <div className="row">
            <div ref={addToRefs} className="row__image">
              <GatsbyImage
                className="image image--left"
                image={getImage(props.data.introimage2.localFile)}
                alt={props.data.introimage2.altText}
                title={props.data.introimage2.title}
              />
            </div>
            <div ref={addToRefs} className="highlightedText row__text"
              dangerouslySetInnerHTML={{
                __html: props.data.introtexthighlight,
            }}/>
          </div>
    </StyledTextImage>
  )
}

export default ImageText
