import styled from "styled-components"

export const StyledTextImage = styled.section`
    position: relative;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.black};
    padding: 2rem 0;

    .row {
        display: flex;
        flex-direction: column-reverse;
        padding: 0 9vw;

        @media ${props => props.theme.sizes.laptop} {
            flex-direction: row;
        }

        &__text {
            display: flex;
            align-items: center;
            padding: 2rem 0;
            z-index: 10;


            @media ${props => props.theme.sizes.laptop} {
                width: 55%;
                padding: 0 5rem;
                max-width: 70ch;
            }
        }
        &__image {
            padding: 0 3rem;
            z-index: 0;

            @media ${props => props.theme.sizes.laptop} {
                width: 40%;
            }
            @media ${props => props.theme.sizes.laptop17} {
                .image {
                    &--right {
                        margin: 0 -3rem -3rem 0;
                    }
                    &--left {
                        margin: -6rem 0 0 -3rem;
                    }
                }
            }

        }
    }
    .regularText {
        font-size: 1.56rem;
    }
    .highlightedText {
        font-size: 1.5rem;
        line-height: 1.5;
        font-weight: 700;
        align-items: flex-end;

        @media ${props => props.theme.sizes.laptop} {
            font-size: 2rem;
            line-height: 1.3;
        }
        @media ${props => props.theme.sizes.laptop17} {
            font-size: 2.5rem;
            line-height: 50px;
        }
    }
`