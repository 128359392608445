import React, { useEffect, useState, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import animationVideo from "../../../../content/assets/animation.mp4"
import styled from "styled-components";


const AnimationVideo = props => {
    const StyledAnimatedVideo = styled.section`
        .plyr {
          --plyr-color-main: #F19206;
        }
        background: #9CD8D1;
        padding: 0 0 60px 0;
        overflow: hidden;

        .title {
            line-height: 206px;
            text-transform: lowercase;
            font-size: clamp(10px,15vmin,150px);
            grid-gap: 20px;
            margin: 0 auto;
            letter-spacing: 10px;
            width: max-content;
            -webkit-text-fill-color: transparent;
            -moz-text-stroke-width: 2px;
            -webkit-text-stroke-width: 2px;
            color: #fff;
            max-width: 100vw;
            text-align: center;

            @media ${props => props.theme.sizes.laptop} {
              font-size: 200px;
              letter-spacing: 20px;
            }
            @media ${props => props.theme.sizes.laptop17} {
              font-size: clamp(180px,35vmin,330px);
              letter-spacing: 30px;
            }
        }

        .wrapper {
            display: flex;
            justify-content: center;
            width: 35%;
            margin: 0 auto;
        }
        .video {
          width: 100%;
          aspect-ratio: 4/3;

            @media ${props => props.theme.sizes.laptop} {
              max-width: 700px;
            }
        }

    `

    gsap.registerPlugin(ScrollTrigger)


    const revealRefs = useRef([]);
    revealRefs.current = [];


    useEffect(() => {
        let options = {
          rootMargin: "0px",
          threshold: [0.25, 0.75]
        };


    }, []);




  return (
    <StyledAnimatedVideo>
        <h2 className="title">Animacja</h2>
        <div className="wrapper">
            <video
              style={{ maxWidth: "100%", width: "100%", margin: "0 auto" }}
              playsInline
              loop
              muted
              controls="false"
              autoPlay
              src={animationVideo}
              ref={revealRefs}
            />
        </div>
    </StyledAnimatedVideo>
  )
}

export default AnimationVideo
