import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { StyledMeme } from "./StyledElements/StyledSMMeme"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ColorCircleElement  from "../../atoms/ColorCirlce"

const SMMeme = props => {

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
      revealRefs.current.forEach((el, index) => {

        gsap.fromTo(el, {
          x: -300,
          autoAlpha: 0
        }, {
          x: 0,
          duration: 1.5,
          autoAlpha: 1,
          ease: 'none',

          scrollTrigger: {
            id: `section-${index+1}`,
            trigger: el,
            start: 'top center+=200',
            end: 'center-=10 center-=50',
            toggleActions: 'play none none reverse',
            scrub: true,
          }
        });

      });
  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  return (
      <StyledMeme CircleElementHeight='10px'>
        <div className="row">
        <div className="column column--text">
            <h3 className="title" dangerouslySetInnerHTML={{
            __html: props.title
            }}/>
            <div className="text">{props.text}</div>
          </div>
          <div className="column column--image">
          {props.circle1 ?
            <div className="cirlce">
              <ColorCircleElement
                color= {props.circle1.color}
                width= {props.circle1.width}
                height={props.circle1.height}
                left={props.circle1.left}
                right={props.circle1.right}
                top={props.circle1.top}
                bottom={props.circle1.bottom}
              />
            </div>
            : ''}
              <GatsbyImage
              className="image image--right"
              image={getImage(props.image.localFile)}
              alt={props.image.altText}
              title={props.image.title}
              />
          </div>
        </div>
      </StyledMeme>
  )
}

export default SMMeme
