import React from "react"
import styled from "styled-components"

const ColorCircle = styled.div`
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    top: ${props => props.top};
    bottom: ${props => props.bottom};
    left: ${props => props.left};
    right: ${props => props.right};
    background: ${props => props.color};
    width: ${props => props.width};
    height: ${props => props.height};

`

const ColorCircleElement = props => {
  return (
    <ColorCircle
    className="circleElement"
    top={props.top}
    bottom={props.bottom}
    left={props.left}
    right={props.right}
    color={props.color}
    width={props.width}
    height={props.height}
    position={props.position}
    >
      {props.children}
    </ColorCircle>
  )
}

export default ColorCircleElement
