import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { StyledFloatingLine } from "./StyledElements/StyledSmallTitle"

const FloatingLine = props => {

  let TitleFirstRow = props.firstLine;

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
      revealRefs.current.forEach((el, index) => {

        gsap.fromTo(el, {
          yPercent: 0
        }, {
          duration: 7.5,
          yPercent: 100,
          ease: 'none',
          scrollTrigger: {
            id: `section-${index+1}`,
            trigger: el,
            start: 'top',
            end: '200%',
            toggleActions: 'play none none reverse',
            scrub: true
          }
        });

      });
  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  return (
    <div style={{width: "99vw", overflow: "hidden"}}>
      <StyledFloatingLine ref={addToRefs} keyFrame='test' transalteStart='-67%' transalteEnd='-7%'>
          <span dangerouslySetInnerHTML={{
            __html: TitleFirstRow
          }}/>
          <span dangerouslySetInnerHTML={{
            __html: TitleFirstRow
          }}/>
          <span dangerouslySetInnerHTML={{
            __html: TitleFirstRow
          }}/>
          <span dangerouslySetInnerHTML={{
            __html: TitleFirstRow
          }}/>
          <span dangerouslySetInnerHTML={{
            __html: TitleFirstRow
          }}/>
      </StyledFloatingLine>
    </div>
  )
}

export default FloatingLine
