import styled from "styled-components"

export const StyledVerticalSlider = styled.section`
    position: relative;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.black};
    padding: 2rem 0;

    .row {
        display: flex;
        flex-direction: column;
        position: relative;

        @media ${props => props.theme.sizes.laptop} {
            flex-direction: row;
        }

        .column {
            @media ${props => props.theme.sizes.laptop} {
                width: 47%;
            }

            &--static {
                display: flex;
                height: auto;
            }
            .fixed {
                width: 100%;
                top: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 5vw 5vw 5vw 7.5vw;

                @media ${props => props.theme.sizes.laptop} {
                    height: 100vh;
                    position: sticky;
                }
            }
            .circleElement {
                @media (max-width: 991.98px) {
                    width: 120vw !important;
                    height: 120vw !important;
                    left: -30vw !important;
                }
            }
            .title {
                font-size: 3rem;

                p {
                    font-weight: 500;
                    line-height: 1.3;
                    color: ${props => props.theme.colors.black};
                }

                @media ${props => props.theme.sizes.laptop} {
                    font-size: 4rem;

                    p {
                        line-height: 1.3;
                    }
                }
                @media ${props => props.theme.sizes.laptopL} {
                    font-size: 5rem;

                    p {
                        line-height: 1.3;
                    }
                }
                @media ${props => props.theme.sizes.desktop} {
                    font-size: 6rem;

                    p {
                        line-height: 1.3;
                    }
                }
            }
            .text {
                font-size: 1.25rem;
                line-height: 2.25rem;
            }
            &--vertical {
                padding: 5vw;

                text-align: center;

                @media ${props => props.theme.sizes.laptop} {
                    width: 53%;
                }

                .image {

                }
                .element {
                    display: flex;
                    flex-direction: column;
                    padding: 2rem 0;

                    &__label {
                        font-size: 1.575rem;
                        margin-bottom: 1rem;

                        @media ${props => props.theme.sizes.laptop17} {
                            font-size: 1.875rem;
                        }
                    }
                    &__number {
                        font-size: 4rem;

                        @media ${props => props.theme.sizes.laptop} {
                            font-size: 5rem;
                        }
                        @media ${props => props.theme.sizes.laptop17} {
                            font-size: 6rem;
                        }
                    }
                    &__text {
                        font-size: 2.2rem;

                        @media ${props => props.theme.sizes.laptop} {
                            font-size: 3.125rem;
                        }
                        @media ${props => props.theme.sizes.laptop17} {
                            font-size: 5.125rem;
                        }
                    }
                    &__number, &__text {
                        font-weight: 700;
                        line-height: 1.1;
                    }
                    &:nth-of-type(4n - 2) {
                        .element__number, .element__text {
                            color: #B8E8C4;
                        }
                    }
                    &:nth-of-type(4n - 3) {
                        .element__number, .element__text {
                            color: #FCBB5A;
                        }
                    }
                    &:nth-of-type(4n - 1) {
                        .element__number, .element__text {
                            color: #71B0F9;
                        }
                    }
                    &:nth-of-type(4n) {
                        .element__number, .element__text {
                            color: #FFF14B;
                        }
                    }

                    &--image {
                        padding: 0;
                    }
                }
            }
        }
    }

`