import React, { useEffect, useState, useRef } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StyledPageSummary, MoreButton} from "./StyledElements/StyledPageSummary"
import ColorCircleElement  from "../../atoms/ColorCirlce"

import gsap from "gsap"


const PageSummary = props => {


  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
      revealRefs.current.forEach((el, index) => {

          gsap.fromTo(el, {
              y: 120,
              autoAlpha: 0
            }, {
              duration: 4.5,
              autoAlpha: 1,
              y: 0,
          scrollTrigger: {
            id: `section-${index+1}`,
            trigger: el,
            start: 'top-=50 center+=200',
            end: 'center+=20 center',
            toggleActions: 'play none none reverse',
            scrub: true,
          }
        });

      });
  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  return (
    <StyledPageSummary className="summary">
        <div className="summary__content" ref={addToRefs}>
            <div className="summary__text"
                dangerouslySetInnerHTML={{
                __html: props.text,
                }}
            />
            <MoreButton>
              <a href={props.link}>napisz do nas >></a>
            </MoreButton>
        </div>
        <ColorCircleElement
            color= {props.circle.color}
            width= {props.circle.width}
            height={props.circle.height}
            left={props.circle.left}
            right={props.circle.right}
            top={props.circle.top}
            bottom={props.circle.bottom}
            position={props.circle.position}
        />
    </StyledPageSummary>
  )
}

export default PageSummary
