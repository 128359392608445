import styled from "styled-components"

export const StyledMeme = styled.section`
    position: relative;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.black};
    padding: 2rem 0;

    @media ${props => props.theme.sizes.laptop} {
        padding: 6rem 0;
    }

    .row {
        display: flex;
        flex-direction: column;
        padding: 0 9vw;
        justify-content: center;

        @media ${props => props.theme.sizes.laptop} {
            flex-direction: row;
        }
    }
    .column {
        @media ${props => props.theme.sizes.laptop} {
            width: 40%;
        }

        &--text {

        }
        &--image {
            position: relative;
        }

    }
    .title {
        color: ${props => props.theme.colors.black};
        margin: 0;

        @media ${props => props.theme.sizes.laptop} {
            text-align: right;
            margin: 3rem 0 2rem;
        }

        p {
            font-size: 3rem;
            line-height: 1.3;
            font-weight: 400;


            @media ${props => props.theme.sizes.laptop} {
                font-size: 6rem;
                line-height: 1;
            }
            @media ${props => props.theme.sizes.laptopL} {
                font-size: 8rem;
                line-height: 1;
            }
            @media ${props => props.theme.sizes.desktop} {
                font-size: 10.4rem;
            }
        }
    }
    .text {
        font-size: 1.55rem;
        text-align: left;
        margin-right: 0;
        margin-left: auto;
        padding: 3rem 0;

        @media ${props => props.theme.sizes.laptop} {
            max-width: 36ch;
            font-size: 1.55rem;
            padding: unset;
        }

    }

`