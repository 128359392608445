import styled from "styled-components"

export const StyledReactions = styled.section`
    position: relative;
    font-family: ${props => props.theme.fonts.primary};
    color: ${props => props.theme.colors.black};
    padding: 2rem 0;

    .row {
        display: flex;
        flex-direction: column;
        padding: 0 9vw;

        @media ${props => props.theme.sizes.laptop} {
            flex-direction: row;
        }
    }
    .column {

        position: relative;

        @media ${props => props.theme.sizes.laptop} {
            width: 50%;
        }

        &--text {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3rem 0;

        }
        &--title {
            display: flex;
            align-items: center;
            padding-left: 5%;
        }
        &--screen {
            position: relative;

            .cirlce {
                position: sticky;
                height: 300px;
                width: 100%;
                top: 0;
            }
        }
    }
    .icon {
            @media (max-width: 991.98px) {
                width: 50px !important;
                height: 50px !important;
            }
        }
    .iconWrapper {

    }
    .title {
        color: ${props => props.theme.colors.black};


        p {
            font-size: 3rem;
            line-height: 1.3;
            font-weight: 400;

            @media ${props => props.theme.sizes.laptop} {
                font-size: 6rem;
                line-height: 1.2;
            }
        }
    }
    .text {
        max-width: 45ch;
        font-size: 1.56rem;
    }
`