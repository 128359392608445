import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { StyledReactions } from "./StyledElements/StyledReactions"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactionIcon  from "../../atoms/ReactionIcon"

const Reactions = props => {

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {

      revealRefs.current.forEach((el, index) => {
        gsap.fromTo(el, {
          // y: 300,
          autoAlpha: 0
        }, {
          // y: 0,
          duration: 2,
          autoAlpha: 1,
          ease: 'none',
          scrollTrigger: {
            id: `section-${index+1}`,
            trigger: el,
            start: 'top-=60 center+=200',
            end: 'top+=100 center',
            toggleActions: 'play none none reverse',
            scrub: true,
          }
        });
        el.querySelectorAll('.icon').forEach((icon, index) => {
          gsap.fromTo(el, {
          }, {
            duration: 1.5,
            autoAlpha: 1,
            ease: 'none',
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: icon,
              start: 'top-=100 center+=100',
              end: 'top+=200 center-=150',
              toggleActions: 'play none none reverse',
              toggleClass: {targets: icon, className: "animated"},
              scrub: true,
            }
          });
        })
      });
  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  return (
      <StyledReactions>
        <div className="row">
          <div ref={addToRefs} className="column column--title">
                <h3 className="title" dangerouslySetInnerHTML={{
                __html: props.data.reactionsTitle
                }}/>
                <ReactionIcon
                iconFile={props.data.loveIcon.localFile}
                iconAltText={props.data.loveIcon.altText}
                iconTtitle={props.data.loveIcon.title}
                delay="0.19"
                duration='1.2'
                width='65px'
                height='65px'
                bottom='10%'
                left='-10%'
                />
          </div>
          <div className="column">
            <div ref={addToRefs} className="iconWrapper">
              <ReactionIcon
                iconFile={props.data.likeIcon.localFile}
                iconAltText={props.data.likeIcon.altText}
                iconTtitle={props.data.likeIcon.title}
                delay="0.14"
                duration='1.2'
                width='80px'
                height='80px'
                bottom='30%'
                left='3%'
              />
              <GatsbyImage
                className="image image--right"
                image={getImage(props.data.reactionsScreen1.localFile)}
                alt={props.data.reactionsScreen1.altText}
                title={props.data.reactionsScreen1.title}
                />
              <ReactionIcon
                iconFile={props.data.loveIcon.localFile}
                iconAltText={props.data.loveIcon.altText}
                iconTtitle={props.data.loveIcon.title}
                delay="0.1"
                duration='1.11'
                width='70px'
                height='70px'
                bottom='70%'
                right='5%'
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <div ref={addToRefs} className="iconWrapper">
              <ReactionIcon
                iconFile={props.data.heartIcon.localFile}
                iconAltText={props.data.heartIcon.altText}
                iconTtitle={props.data.heartIcon.title}
                delay="0.1"
                duration='1.11'
                width='70px'
                height='70px'
                bottom='70%'
                right='5%'
              />
              <GatsbyImage
              className="image image--right"
              image={getImage(props.data.reactionsScreen2.localFile)}
              alt={props.data.reactionsScreen2.altText}
              title={props.data.reactionsScreen2.title}
              />
              <ReactionIcon
                iconFile={props.data.heartIcon.localFile}
                iconAltText={props.data.heartIcon.altText}
                iconTtitle={props.data.heartIcon.title}
                delay="0.2"
                duration='1.21'
                width='50px'
                height='50px'
                bottom='20%'
                left='5%'
              />
              <ReactionIcon
                iconFile={props.data.likeIcon.localFile}
                iconAltText={props.data.likeIcon.altText}
                iconTtitle={props.data.likeIcon.title}
                delay="0.25"
                duration='1.11'
                width='70px'
                height='70px'
                bottom='40%'
                left='-15%'
              />
            </div>
          </div>
          <div ref={addToRefs} className="column column--text">
            <div className="text">
              {props.data.reactionsText}
            </div>
            <ReactionIcon
                iconFile={props.data.likeIcon.localFile}
                iconAltText={props.data.likeIcon.altText}
                iconTtitle={props.data.likeIcon.title}
                delay="0.25"
                duration='1.11'
                width='70px'
                height='70px'
                bottom='80%'
                right='-10%'
              />
            <ReactionIcon
                iconFile={props.data.heartIcon.localFile}
                iconAltText={props.data.heartIcon.altText}
                iconTtitle={props.data.heartIcon.title}
                delay="0.35"
                duration='1.01'
                width='60px'
                height='60px'
                bottom='35%'
                right='-2.5%'
              />
          </div>
        </div>
        <div className="row">
          <div className="column">

          </div>
          <div ref={addToRefs} className="column">
            <GatsbyImage
              className="image image--right"
              image={getImage(props.data.reactionsScreen3.localFile)}
              alt={props.data.reactionsScreen3.altText}
              title={props.data.reactionsScreen3.title}
              />
              <ReactionIcon
                iconFile={props.data.loveIcon.localFile}
                iconAltText={props.data.loveIcon.altText}
                iconTtitle={props.data.loveIcon.title}
                delay="0.35"
                duration='1.21'
                width='70px'
                height='70px'
                bottom='45%'
                left='4%'
              />
              <ReactionIcon
                iconFile={props.data.likeIcon.localFile}
                iconAltText={props.data.likeIcon.altText}
                iconTtitle={props.data.likeIcon.title}
                delay="0.15"
                duration='1.11'
                width='70px'
                height='70px'
                bottom='85%'
                right='10%'
              />
          </div>
        </div>
      </StyledReactions>
  )
}

export default Reactions
