import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ReactionIcon = styled.div`
    position: absolute;
    border-radius: 50%;
    top: ${props => props.top};
    bottom: ${props => props.bottom};
    left: ${props => props.left};
    right: ${props => props.right};
    width: ${props => props.width};
    height: ${props => props.height};
    z-index: 10;
    opacity: 0;

    &.animated {
        animation: reaction ${props => props.duration}s cubic-bezier(0.550, 0.085, 0.680, 0.530) ${props => props.delay}s both;

        @keyframes reaction {
        0% {
            animation-timing-function: ease-in;
            opacity: 1;
            transform: translateY(256px);
        }

        24% {
            opacity: 1;
        }

        40% {
            animation-timing-function: ease-in;
            transform: translateY(64px);
        }

        65% {
            animation-timing-function: ease-in;
            transform: translateY(32px);
        }

        82% {
            animation-timing-function: ease-in;
            transform: translateY(16px);
        }

        93% {
            animation-timing-function: ease-in;
            transform: translateY(8px);
        }

        25%,
        55%,
        75%,
        87% {
            animation-timing-function: ease-out;
            transform: translateY(0px);
        }

        100% {
            animation-timing-function: ease-out;
            opacity: 1;
            transform: translateY(0px);
        }
        }
    }

`

const ReactionIconElement = props => {
  return (
    <ReactionIcon
    className="icon"
    top={props.top}
    bottom={props.bottom}
    left={props.left}
    right={props.right}
    width={props.width}
    height={props.height}
    delay={props.delay}
    duration={props.duration}
    >
      <GatsbyImage
        image={getImage(props.iconFile)}
        alt={props.iconAltText}
        title={props.iconTtitle}
        />
    </ReactionIcon>
  )
}

export default ReactionIconElement
