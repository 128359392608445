import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { StyledExtScreen } from "./StyledElements/StyledExtScreen"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ColorCircleElement  from "../../atoms/ColorCirlce"

const ExtendedScreenshots = props => {

  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
      ScrollTrigger.matchMedia({
        "(max-width: 800px)": function() {
          revealRefs.current.forEach((el, index) => {
              gsap.fromTo(el, {
                y: 90,
                autoAlpha: 0
              }, {
                duration: 2.5,
                autoAlpha: 1,
                y: 0,
                ease: 'none',
                scrollTrigger: {
                  id: `section-${index+1}`,
                  trigger: el,
                  start: 'top-=140 center+=40',
                  end: 'center center',
                  toggleActions: 'play none none reverse',
                  scrub: true,
                }
              });
          });
        }
      })
      ScrollTrigger.matchMedia({
        "(min-width: 800px)": function() {
          revealRefs.current.forEach((el, index) => {
            if (el.classList.contains('column--text')) {
              gsap.fromTo(el, {
                y: -10,
                // autoAlpha: 0
              }, {
                duration: 1.5,
                // autoAlpha: 1,
                y: 220,
                ease: 'none',
                scrollTrigger: {
                  id: `section-${index+1}`,
                  trigger: el,
                  start: 'top+=200 center+=60',
                  end: 'center+=250 center+=50',
                  toggleActions: 'play none none reverse',
                  scrub: true,
                }
              });
            } else if (el.classList.contains('carouselImage')) {

            gsap.fromTo(el, {
              x: -300,
              autoAlpha: 0
            }, {
              x: 0,
              duration: 1.5,
              autoAlpha: 1,
              ease: 'none',

              scrollTrigger: {
                id: `section-${index+1}`,
                trigger: el,
                start: 'top center+=200',
                end: 'center-=150 center-=50',
                toggleActions: 'play none none reverse',
                scrub: true,
              }
            });
          }

          });
        }
      })
  }, []);

  const addToRefs = el => {
      if (el && !revealRefs.current.includes(el)) {
          revealRefs.current.push(el);
      }
  };

  return (
      <StyledExtScreen
        CircleElementHeight='150px'
      >
        <div className="row">
          <div className="column">
              <GatsbyImage
              className="image image--right"
              image={getImage(props.data.verticalScreen1.localFile)}
              alt={props.data.verticalScreen1.altText}
              title={props.data.verticalScreen1.title}
              />
          </div>
          <div className="column column--text" ref={addToRefs}>
            <h3 className="title" dangerouslySetInnerHTML={{
            __html: props.data.esTitle
            }}/>
            <div className="text">{props.data.esText1}</div>

          </div>
          <div className="column column--screen">
            <div className="cirlce">
              <ColorCircleElement
                  color="#8DD8CF"
                  width="477px"
                  height="477px"
                  right="-100px"
                  top='0'
                  position="relative"
              />
            </div>
            <GatsbyImage
              className="image image--right"
              image={getImage(props.data.verticalScreen2.localFile)}
              alt={props.data.verticalScreen2.altText}
              title={props.data.verticalScreen2.title}
              />
          </div>
        </div>
        <div className="row">
          <div className="carouselText text " ref={addToRefs}>{props.data.esText2}</div>
        </div>
        <div className="row">
          <div ref={addToRefs} className="carouselImage">
            <GatsbyImage
              className="image image--right"
              image={getImage(props.data.carouselScreen.localFile)}
              alt={props.data.carouselScreen.altText}
              title={props.data.carouselScreen.title}
              />
          </div>
        </div>
      </StyledExtScreen>
  )
}

export default ExtendedScreenshots
